import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { isEmpty, isLoaded, useFirestore } from 'react-redux-firebase';
import { firebaseConfig } from '../config';

import { login, register, logout } from '../redux/slices/authJwt';

useAuth.propTypes = {
  method: PropTypes.oneOf(['jwt', 'firebase'])
};

firebase.initializeApp(firebaseConfig);
export default function useAuth(method = 'jwt') {
  const { enqueueSnackbar } = useSnackbar();

  const firestore = useFirestore();
  const { auth, profile } = useSelector((state) => state.firebase);

  const dispatch = useDispatch();
  const { user, isLoading, isAuthenticated } = useSelector(
    (state) => state.authJwt
  );

  if (method === 'jwt') {
    return {
      method: 'jwt',
      user,
      isLoading,
      isAuthenticated,

      login: ({ email, password }) =>
        firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() =>
            firebase
              .auth()
              .signInWithEmailAndPassword(email, password)
              .then((userAuth) => {
                const { user } = userAuth;
                enqueueSnackbar('Login success', {
                  variant: 'success'
                });
                dispatch(login(user));
              })
              .catch((e) =>
                enqueueSnackbar(e.message, {
                  variant: 'error'
                })
              )
          ),
      register: (user) =>
        firebase
          .auth()
          .createUserWithEmailAndPassword(user.email, user.password)
          .then((userAuth) => {
            const { uid } = userAuth.user;
            const loggedUser = { ...user, uid };
            dispatch(register(loggedUser));
          })
          .catch((e) => console.log(e.code)),

      logout: () => dispatch(logout()),

      resetPassword: (email) => {
        firebase.auth().sendPasswordResetEmail(email);
      },

      updateProfile: () => {}
    };
  }
  return {
    method: 'firebase',
    user: {
      displayName: auth.displayName || profile.displayName || '',
      email: auth.email || '',
      photoURL: auth.photoURL || profile.photoURL || '',
      phoneNumber: auth.phoneNumber || profile.phoneNumber || '',
      country: profile.country || '',
      address: profile.address || '',
      state: profile.state || '',
      city: profile.city || '',
      zipCode: profile.zipCode || '',
      about: profile.about || '',
      role: profile.role || '',
      isPublic: profile.isPublic || false
    },
    isLoading: !isLoaded(auth),
    isAuthenticated: !isEmpty(auth),

    login: ({ email, password }) =>
      firebase.auth
        .createUserWithEmailAndPassword(email, password)
        .then(() => {})
        .catch((e) => console.log(e.code)),
    loginWithGoogle: () =>
      firebase.login({ provider: 'google', type: 'popup' }),

    loginWithFaceBook: () =>
      firebase.login({ provider: 'facebook', type: 'popup' }),

    loginWithTwitter: () =>
      firebase.login({ provider: 'twitter', type: 'popup' }),

    register: ({ email, password, firstName, lastName }) =>
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((res) => {
          firestore
            .collection('users')
            .doc(res.user.uid)
            .set({
              uid: res.user.uid,
              email,
              displayName: `${firstName} ${lastName}`
            });
        }),

    logout: () => firebase.logout(),

    resetPassword: (email) => firebase.resetPassword(email),

    updateProfile: (values) =>
      firebase
        .auth()
        .currentUser.updateProfile(values)
        .then((res) => {
          firestore
            .collection('users')
            .doc(res.id)
            .set(values, { merge: true });
        })
  };
}
