// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ user, company, ...other }) {
  return (
    <>
      {user && (
        <MAvatar
          src={user.photoURL}
          alt={user.displayName}
          color={
            user.photoURL
              ? 'default'
              : createAvatar(user.displayName || user.email).color
          }
          {...other}
        >
          {createAvatar(user.displayName).name}
        </MAvatar>
      )}
      {company && (
        <MAvatar
          src={company.photoURL}
          alt={company.displayName}
          color={
            company.photoURL
              ? 'default'
              : createAvatar(company.displayName || company.email).color
          }
          {...other}
        >
          {createAvatar(company.displayName).name}
        </MAvatar>
      )}
    </>
  );
}
