import jwtDecode from 'jwt-decode';
import { createSlice } from '@reduxjs/toolkit';
import jwt from 'jsonwebtoken';

import firebase from 'firebase/app';
import axios from '../../utils/axios';
import 'firebase/database';
import { getUserByUid } from '../../_apis_/user';
import { setCurrentCompany, setCurrentBranch } from './company';
import { getCompanyById } from '../../_apis_/companies';
import { getBranchById } from '../../_apis_/branches';

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  user: {}
};

const slice = createSlice({
  name: 'authJwt',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
    },

    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    registerSuccess(state, action) {
      // state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    logoutSuccess(state) {
      state.isAuthenticated = false;
      localStorage.removeItem('userId');
      localStorage.removeItem('companyId');
      localStorage.removeItem('branchId');
      state.user = null;
    }
  }
});

export default slice.reducer;

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export function login(user) {
  return async (dispatch) => {
    const JWT_SECRET = 'minimal-secret-key';
    const JWT_EXPIRES_IN = '5 days';
    const accessToken = jwt.sign({ userId: user.id }, JWT_SECRET, {
      expiresIn: JWT_EXPIRES_IN
    });

    if (isValidToken(accessToken)) {
      setSession(accessToken);
    }
    localStorage.setItem('userId', user.uid);
    dispatch(slice.actions.loginSuccess({ user }));
  };
}

export function register(user) {
  return async (dispatch) => {
    const newUserRef = firebase.database().ref(`/users/${user.uid}`);
    newUserRef.set({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      displayName: user.firstName + user.lastName,
      companies: ['1']
    });

    localStorage.setItem('accessToken', user.email);
    dispatch(slice.actions.registerSuccess({ user }));
  };
}

export function logout() {
  return async (dispatch) => {
    localStorage.removeItem('userId');
    localStorage.removeItem('companyId');
    localStorage.removeItem('branchSelected');
    localStorage.removeItem('branchId');
    localStorage.removeItem('redux-authJwt');
    dispatch(setCurrentCompany(''));
    dispatch(setCurrentBranch(''));

    setSession(null);
    dispatch(slice.actions.logoutSuccess());
  };
}

export function getInitialize() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken) {
        setSession(accessToken);
        const userId = localStorage.getItem('userId');
        const userData = await getUserByUid(userId);
        userData.uid = userId;
        const companyId = localStorage.getItem('companyId');
        const currentCompany = await getCompanyById(companyId);

        const branchId = localStorage.getItem('branchId');
        const currentBranch = await getBranchById(branchId);
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user: userData || {}
          })
        );

        if (currentBranch?.id !== 'null') {
          dispatch(setCurrentCompany(currentCompany));
          dispatch(setCurrentBranch(currentBranch));
        }
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: null
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null
        })
      );
    }
  };
}
