import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  currentCompany: '',
  branches: [],
  currentBranch: '',
  oldInvoice: ''
};

const currentBranch = localStorage.getItem('branchSelected');
if (currentBranch) {
  initialState.currentBranch = currentBranch;
}

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // // SET CURRENT COMPANY
    // setCompanyId(state, action) {
    //   state.companyId = action.payload;
    // },
    // // SET CURRENT Branch
    // setBranchId(state, action) {
    //   state.branchId = action.payload;
    // },
    // SET CURRENT Branch
    setCurrentBranch(state, action) {
      // const currentBranch = localStorage.getItem('branchSelected');
      // if (currentBranch) {
      //   state.currentBranch = currentBranch;
      // } else state.currentBranch = action.payload;

      state.currentBranch = action.payload;
      /*       {invoiceNumber} = action.payload;
      state.currentBranch = action.payload; */
    },
    // SET CURRENT Branch
    setCurrentCompany(state, action) {
      // const currentCompany = localStorage.getItem('companySelected');
      // if (currentCompany) {
      //   state.currentCompany = currentCompany;
      // } else state.currentCompany = action.payload;
      state.currentCompany = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  // setCompanyId,
  // setBranchId,
  setCurrentBranch,
  setCurrentCompany
} = slice.actions;
