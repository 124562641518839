import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { getUserByUid } from './user';

export const getCompaniesByUser = async (user) => {
  const companies = [];
  const companyIds = Array.isArray(user.companies) ? user.companies : [];
  await Promise.all(
    companyIds.map(async (id) => {
      await firebase
        .database()
        .ref(`companies/${id}`)
        .once('value')
        .then((snap) => {
          const snapData = snap.val();
          const company = {
            ...snapData,
            id: snap.key
          };
          if (company.comercialName) {
            companies.push(company);
          }
        });
    })
  );
  return companies;
};

export const createNewCompany = async (company) => {
  const rootRef = firebase.database().ref('/');
  const companies = rootRef.child('companies');
  const usersRef = rootRef.child('users');
  const companyOwner = usersRef.child(company.rootUser);
  const newCompany = await companies.push(company);

  const rootUser = await getUserByUid(company.rootUser);
  const userCompanies = rootUser.companies || [];
  const updatedRootUser = {
    ...rootUser,
    companies: [...userCompanies, newCompany.key]
  };
  companyOwner.update(updatedRootUser);
};

export const getCompanyById = async (id) => {
  let fetchedCompany = {};

  await firebase
    .database()
    .ref(`companies/${id}`)
    .once('value')
    .then((snap) => {
      const snapData = snap.val();
      const company = {
        ...snapData,
        id: snap.key
      };

      fetchedCompany = company;
    });

  return fetchedCompany;
};

export const updateCompany = async (company, currentUserId) => {
  const currentCompany = await getCompanyById(company.id);
  const rootRef = firebase.database().ref('/');
  const companies = rootRef.child('companies');

  const currentCompanyRef = companies.child(company.id);
  const newRecord = {
    date: new Date().toISOString(),
    modifierUser: currentUserId,
    prevValue: currentCompany,
    updatedValue: company
  };
  const updatedCompany = {
    ...company,
    history: [...company.history, newRecord]
  };
  currentCompanyRef.update(updatedCompany);
};

export const deleteCompany = async (company) => {
  const rootRef = firebase.database().ref('/');
  const companies = rootRef.child('companies');
  const usersRef = rootRef.child('users');

  // Delete the company from the user owners
  const updatedUsers = await company.authorizedUsersIds.map(async (userId) => {
    const currentUser = await getUserByUid(userId);
    const currentUserRef = usersRef.child(userId);

    const nonDeletedCompanies = currentUser.companies?.filter(
      (item) => item !== company.id
    );
    const updatedUser = {
      ...currentUser,
      companies: nonDeletedCompanies
    };
    await currentUserRef.update(updatedUser);
  });
  await Promise.all(updatedUsers);

  // Delete company
  const currentCompanyRef = companies.child(company.id);
  currentCompanyRef.remove();
};
