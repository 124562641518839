import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { getBranchById } from './branches';

export const registerBank = async (bank, branchId) => {
  const rootRef = firebase.database().ref('/');
  const banks = rootRef.child(`banks/${branchId}`);
  const branches = rootRef.child('branches');
  const parentBranchRef = branches.child(branchId);
  const parentBranch = await getBranchById(branchId);

  const newBank = await banks.push(bank);
  const getBankCreated = await getBankById(newBank.key, branchId);
  const findBankCreated = banks.child(`${newBank.key}`);
  const updateBankWithId = await findBankCreated.set(getBankCreated);

  await updateBankWithId;
  const branchbanks = parentBranch.banks?.filter((item) => item) || [];
  const updatedParentBranch = {
    ...parentBranch,
    banks: [...branchbanks, newBank.key]
  };

  const update = await parentBranchRef.update(updatedParentBranch);

  return update;
};

export const updateBank = async (bank, branchId) => {
  const rootRef = firebase.database().ref('/');
  const banks = rootRef.child(`banks/${branchId}`);
  const currentInvoiceRef = banks.child(bank.id);

  currentInvoiceRef.update(bank);
};

export const banishBank = async (bank, branchId) => {
  const rootRef = firebase.database().ref('/');
  const banksBanished = rootRef.child(`banished/bank/${branchId}`);

  await banksBanished.push(bank);
};

export const deleteBank = async (bankId, branchId) => {
  const bankRemoved = await getBankById(bankId, branchId);
  bankRemoved.status = 'removed';
  await banishBank(bankRemoved, branchId);

  const rootRef = firebase.database().ref('/');

  const banks = rootRef.child(`banks/${branchId}`);
  const currentBankRef = banks.child(bankId);
  currentBankRef.remove();

  const branches = rootRef.child('branches');
  const parentBranchRef = branches.child(branchId);
  const parentBranch = await getBranchById(branchId);
  const banksArray = parentBranch?.banks;

  const branchBanks = banksArray.filter((item) => item !== bankId);

  const updatedParentBranch = {
    ...parentBranch,
    banks: branchBanks
  };

  const update = await parentBranchRef.update(updatedParentBranch);
  return update;
};

export const getBanksByBranch = async (currentBranch) => {
  const banksList = [];

  const listBanksId = currentBranch.banks || [];

  await Promise.all(
    listBanksId.map(async (id) => {
      await firebase
        .database()
        .ref(`banks/${currentBranch.id}/${id}`)
        .once('value')
        .then((snap) => {
          const snapData = snap.val();
          const bank = {
            ...snapData,
            id: snap.key
          };
          banksList.push(bank);
        });
    })
  );

  return banksList;
};

export const getBankById = async (bankId, branchId) => {
  const promise = firebase
    .database()
    .ref(`banks/${branchId}/${bankId}`)
    .once('value')
    .then((snap) => {
      const snapData = snap.val();
      const bank = {
        ...snapData,
        id: snap.key
      };
      return bank;
    });

  return promise;
};
