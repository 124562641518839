import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';

import chatLeftFill from '@iconify/icons-bi/chat-left-fill';

import { Box, Tooltip, IconButton } from '@material-ui/core';

import useAuth from '../../hooks/useAuth';

import { MFab } from '../@material-extend';
import chatApi from '../chatScript';

export default function ChatButton() {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);

  const handleOpenChatButton = () => {
    setOpen(true);
  };

  const handleCloseChatButton = () => {
    setOpen(false);
  };

  useEffect(() => {
    const MyG2C = document.createElement('script');
    MyG2C.id = 'newloaderthewavev5';
    MyG2C.type = 'text/javascript';
    MyG2C.src =
      '//omni360.itriux.com/hermes360/PlateformPublication/WebSitesLiveChat/Commun/TheWave/A5156727738495B4_ZV6MxAMK/loaderthenewwave.ashx';
    MyG2C.async = true;

    document.body.appendChild(MyG2C);
  }, []);

  return (
    <>
      {user && (
        <Box
          sx={{
            position: 'fixed',
            bottom: { xs: 16, sm: 24, md: 32 },
            right: { xs: 16, sm: 24, md: 100 },
            zIndex: 999
          }}
        >
          <Tooltip title="Chat">
            <MFab
              color="success"
              size="medium"
              onClick={() => chatApi()}
              sx={{
                color: (theme) => theme.palette.success.contrastText,
                background: (theme) => theme.palette.gradients.success
              }}
            >
              <IconButton>
                <Icon
                  icon={chatLeftFill}
                  color="white"
                  width="23"
                  height="23"
                />
              </IconButton>
            </MFab>
          </Tooltip>
        </Box>
      )}
    </>
  );
}
