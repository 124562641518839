export const cloudinaryConfig = {
  cloudinaryKey: process.env.REACT_APP_CLOUDINARY_KEY,
  cloudinaryPreset: process.env.REACT_APP_CLOUDINARY_PRESET,
  cloudinaryUrl: process.env.REACT_APP_CLOUDINARY_URL
};

export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;
export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

const DEV_FIREBASE_CONFIG = {
  apiKey: 'AIzaSyBllEA06_W-ApIlzxQFztc2vuSlSbXUnWc',
  authDomain: 'sg-kekuii-dev.firebaseapp.com',
  databaseURL: 'https://sg-kekuii-dev-default-rtdb.firebaseio.com',
  projectId: 'sg-kekuii-dev',
  storageBucket: 'sg-kekuii-dev.appspot.com',
  messagingSenderId: '333828154068',
  appId: '1:333828154068:web:8c5aa38dbfb6e349ed1141',
  measurementId: 'G-T2M4W708Q2'
};

/* const DEV_FIREBASE_CONFIG = {
  apiKey:
    process.env.REACT_APP_FIREBASE_REALTIME ||
    'AIzaSyDxt8jDhCyEmgCLyzqNh212xewjOdzCWkQ',
  authDomain:
    process.env.REACT_APP_FIREBASE_REALTIME_AUTH_DOMAI0N ||
    'kekuii.firebaseapp.com',
  projectId: process.env.REACT_APP_FIREBASE_REALTIME_PROJECT_ID || 'kekuii',
  storageBucket:
    process.env.REACT_APP_FIREBASE_REALTIME_STORAGE_BUCKET ||
    'kekuii.appspot.com',
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_REALTIME_MESSAGING_SENDER_ID ||
    '288303234808',
  appId:
    process.env.REACT_APP_FIREBASE_REALTIME_APPID ||
    '1:288303234808:web:1d4109406da809938fc50d',
  measurementId:
    process.env.REACT_APP_FIREBASE_REALTIME_MEASUREMENT_ID || 'G-DB390HPWEY'
}; */

const PROD_FIREBASE_CONFIG = {
  apiKey:
    process.env.REACT_APP_PROD_FIREBASE_REALTIME ||
    'AIzaSyBYGtj7ca54I4OYDz7QrtxWu4PTqsm8HvA',
  authDomain:
    process.env.REACT_APP_PROD_FIREBASE_REALTIME_AUTH_DOMAIN ||
    'sg-kekuii.firebaseapp.com',
  projectId:
    process.env.REACT_APP_PROD_FIREBASE_REALTIME_PROJECT_ID || 'sg-kekuii',
  storageBucket:
    process.env.REACT_APP_PROD_FIREBASE_REALTIME_STORAGE_BUCKET ||
    'sg-kekuii.appspot.com',
  messagingSenderId:
    process.env.REACT_APP_PROD_FIREBASE_REALTIME_MESSAGING_SENDER_ID ||
    '398627987087',
  appId:
    process.env.REACT_APP_PROD_FIREBASE_REALTIME_APPID ||
    '1:398627987087:web:b871a3a40ccc14dadc9afa',
  measurementId:
    process.env.REACT_APP_PROD_FIREBASE_REALTIME_MEASUREMENT_ID ||
    'G-436VBHJZCW'
};

export const firebaseConfig = DEV_FIREBASE_CONFIG;
