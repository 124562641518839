import 'firebase/database';

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';

import { Icon } from '@iconify/react';
import legacyfilemanagerIcon from '@iconify/icons-whh/legacyfilemanager';
import profileIcon from '@iconify/icons-gg/profile';

import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  Link,
  List,
  Button,
  Drawer,
  Hidden,
  Typography,
  ListSubheader
} from '@material-ui/core';
import { MIconButton } from '../../components/@material-extend';

import { getUserByUid } from '../../_apis_/user';

import useAuth from '../../hooks/useAuth';

import { PATH_DASHBOARD, PATH_DOCS } from '../../routes/paths';

import Logo from '../../components/Logo';
import MyAvatar from '../../components/MyAvatar';
import Scrollbar from '../../components/Scrollbar';

import MenuLinks from './SidebarConfig';
import SidebarItem from './SidebarItem';

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  margin: theme.spacing(1, 2.5, 1),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

const DocStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadiusMd,
  backgroundColor:
    theme.palette.mode === 'light'
      ? alpha(theme.palette.primary.main, 0.08)
      : theme.palette.primary.lighter
}));

function reduceChild({ array, item, pathname, level }) {
  const key = item.href + level;

  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    return [
      ...array,
      <SidebarItem
        key={key}
        level={level}
        icon={item.icon}
        info={item.info}
        href={item.href}
        title={item.title}
        open={Boolean(match)}
      >
        {renderSidebarItems({
          pathname,
          level: level + 1,
          items: item.items
        })}
      </SidebarItem>
    ];
  }
  return [
    ...array,
    <SidebarItem
      key={key}
      level={level}
      href={item.href}
      icon={item.icon}
      info={item.info}
      title={item.title}
    />
  ];
}

function renderSidebarItems({ items, pathname, level = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (array, item) => reduceChild({ array, item, pathname, level }),
        []
      )}
    </List>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { userProfile } = useSelector((state) => state.user);

  const companyLocal = localStorage.getItem('companyId');
  const company = useSelector((state) => state.company?.currentCompany);
  const branch = useSelector((state) => state.company?.currentBranch);

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const [currentUser, setCurrentUser] = useState();

  const getProfileUser = async () => {
    const userId = localStorage.getItem('userId');
    const profile = await getUserByUid(userId);
    setCurrentUser(profile);
  };

  useEffect(() => {
    getProfileUser();
    setCurrentUser(userProfile);
  }, [userProfile]);

  const renderContent = (
    <Scrollbar>
      <Box sx={{ px: 2.5, py: 3 }}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </Box>

      <Link
        underline="none"
        component={RouterLink}
        to={PATH_DASHBOARD.user.profile}
      >
        <AccountStyle>
          <Box>
            <Icon icon={legacyfilemanagerIcon} width="40" height="40" />
          </Box>
          <Box sx={{ ml: 0.5 }}>
            <Typography variant="h6" sx={{ color: 'text.primary' }}>
              Administración
            </Typography>
          </Box>
        </AccountStyle>
      </Link>

      {company && companyLocal && (
        <Link
          underline="none"
          component={RouterLink}
          to={`${PATH_DASHBOARD.company.root}/${company.id}`}
        >
          <AccountStyle>
            <MyAvatar company={company} />
            <Box sx={{ ml: 0.5 }}>
              <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                {company.comercialName}
              </Typography>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                {branch.name}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      )}

      <Link
        underline="none"
        component={RouterLink}
        to={PATH_DASHBOARD.user.account}
      >
        <AccountStyle sx={{ width: 'auto' }}>
          <Box>
            {userProfile?.photoURL || currentUser?.photoURL ? (
              <MyAvatar
                user={userProfile || currentUser}
                width="40"
                height="40"
              />
            ) : (
              <Icon icon={profileIcon} width="40" height="40" />
            )}
          </Box>

          <Box sx={{ ml: 0.5 }}>
            <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
              {userProfile?.displayName ||
                currentUser?.displayName ||
                `${user.firstName} ${user.lastName}`}
            </Typography>
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              {userProfile?.email || user.email}
            </Typography>
          </Box>
        </AccountStyle>
      </Link>

      {MenuLinks.map((list) =>
        list.subheader === 'administracion' ||
        list.subheader === 'e-commerce' ||
        list.subheader === 'legal' ? (
          branch && (
            <List
              disablePadding
              key={list.subheader}
              subheader={
                <ListSubheader
                  disableSticky
                  disableGutters
                  sx={{
                    mt: 3,
                    mb: 2,
                    pl: 5,
                    color: 'text.primary',
                    typography: 'overline'
                  }}
                >
                  {list.subheader}
                </ListSubheader>
              }
            >
              {renderSidebarItems({
                items: list.items,
                pathname
              })}
            </List>
          )
        ) : (
          <List
            disablePadding
            key={list.subheader}
            subheader={
              <ListSubheader
                disableSticky
                disableGutters
                sx={{
                  mt: 3,
                  mb: 2,
                  pl: 5,
                  color: 'text.primary',
                  typography: 'overline'
                }}
              >
                {list.subheader}
              </ListSubheader>
            }
          >
            {renderSidebarItems({
              items: list.items,
              pathname
            })}
          </List>
        )
      )}

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <DocStyle>
          <Box
            component="img"
            alt="doc"
            src="/static/icons/ic_doc.svg"
            sx={{ width: 36, height: 36, mb: 2 }}
          />
          <Typography
            gutterBottom
            variant="subtitle1"
            sx={{ color: 'grey.800' }}
          >
            Hi,
            {currentUser?.displayName || user.displayName}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, color: 'grey.600' }}>
            Need help?
            <br /> Please check our docs
          </Typography>
          <Button
            fullWidth
            to={PATH_DOCS.root}
            variant="contained"
            component={RouterLink}
          >
            Documentation
          </Button>
        </DocStyle>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <Hidden lgUp>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </RootStyle>
  );
}
